import { SelectProperty } from "@/data/giveaway_types";

export interface Platform {
  label: string;
  values: string[];
}

export interface Type {
  label: string;
  values: string[];
}

export interface Supplier {
  label: string;
  value: string;
}

export const platforms: Platform[] = [
  { label: "Steam", values: ["steam"] },
  { label: "Epic Games", values: ["epic_games"] },
  { label: "Origin", values: ["origin"] },
  { label: "GOG", values: ["gog"] },
  { label: "Uplay", values: ["ubisoft_connect"] },
  { label: "Battle.net", values: ["battle_net"] },
  { label: "Microsoft", values: ["microsoft"] },
  { label: "Amazon", values: ["amazon"] },
  { label: "Indiegala", values: ["indiegala"] },
  { label: "itch.io", values: ["itch_io"] },
  {
    label: "PC",
    values: ["pc"],
  },
  {
    label: "PlayStation",
    values: ["playstation"],
  },
  {
    label: "PS4",
    values: ["ps4"],
  },
  {
    label: "PS5",
    values: ["ps5"],
  },
  { label: "Xbox", values: ["xbox"] },
  { label: "Xbox 360", values: ["xbox_360"] },
  { label: "Xbox One", values: ["xbox_one"] },
  { label: "Nintendo", values: ["nintendo"] },
  { label: "Nintendo Switch", values: ["switch"] },
  { label: "Mobile", values: ["mobile"] },
  { label: "Android", values: ["android"] },
  { label: "iOS", values: ["ios"] },
  { label: "VR", values: ["oculus"] },
  { label: "Other", values: [] },
];

export const platformsAggregated: Platform[] = [
  { label: "Steam", values: ["steam"] },
  { label: "EpicGames", values: ["epic_games"] },
  { label: "Origin", values: ["origin"] },
  { label: "GOG", values: ["gog"] },
  { label: "Uplay", values: ["ubisoft_connect"] },
  { label: "Battle.net", values: ["battle_net"] },
  { label: "Microsoft", values: ["microsoft"] },
  { label: "Amazon", values: ["amazon"] },
  { label: "Indiegala", values: ["indiegala"] },
  { label: "itch.io", values: ["itch_io"] },
  {
    label: "PC",
    values: [
      "pc",
      "steam",
      "epic_games",
      "origin",
      "gog",
      "ubisoft_connect",
      "battle_net",
      "microsoft",
      "amazon",
      "indiegala",
      "itch_io",
    ],
  },
  {
    label: "PlayStation",
    values: ["playstation", "ps4", "ps5"],
  },
  { label: "Xbox", values: ["xbox", "xbox_360", "xbox_one"] },
  { label: "Nintendo", values: ["nintendo", "switch"] },
  { label: "Mobile", values: ["ios", "android", "mobile"] },
  { label: "VR", values: ["oculus"] },
  { label: "Other", values: [] },
];

export const types: Type[] = [
  { label: "Game", values: ["GAME"] },
  { label: "DLC", values: ["DLC"] },
  { label: "Loot", values: ["LOOT"] },
  { label: "Demo", values: ["DEMO"] },
  { label: "Alpha/Beta", values: ["ALPHA", "BETA"] },
  { label: "Other", values: [] },
];

export const giveawayPlatformsAggregatedFilter: SelectProperty[] =
  platformsAggregated.map((item: Platform) => {
    return {
      id: item.label,
      name: item.label,
      description: "",
      checked: true,
    };
  });

export const giveawayTypesFilter: SelectProperty[] = types.map((item: Type) => {
  return {
    id: item.label,
    name: item.label,
    description: "",
    checked: true,
  };
});
