"use client";

import React, { FC, useEffect, useState } from "react";

export interface PricesProps {
  className?: string;
  price?: string;
}

const Prices: FC<PricesProps> = ({ className = "", price = "" }) => {
  return (
    <div className={`${className}`}>
      <div className={`flex items-baseline relative`}>
        <span className="line-through text-neutral-500 dark:text-neutral-400 !leading-none">
          {price}
        </span>
      </div>
    </div>
  );
};

export default Prices;
