"use client";

import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import useGiveawaysFilters, {
  useGiveawaysFilteredData,
} from "@/hooks/useGiveawaysFilters";
import { GiveawayFixture } from "@/data/giveaway_types";
import { fetchGiveaways } from "@/utils/fetchGiveaways";

const Pagination: FC = () => {
  const [data, setData] = useState<GiveawayFixture[]>([]);
  const { expiredState } = useGiveawaysFilters();
  const { total } = useGiveawaysFilteredData(data, {
    expiration: expiredState,
  });
  const { goToNextPage, limit, nextPageAvailable, setNextPageAvailable } =
    useGiveawaysFilters();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchGiveaways();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (nextPageAvailable && limit >= total) {
    setNextPageAvailable(false);
    return null;
  } else if (!nextPageAvailable && limit < total) {
    setNextPageAvailable(true);
  } else if (!nextPageAvailable) return null;

  return (
    <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
      <div></div>
      <ButtonPrimary onClick={goToNextPage}>Show me more</ButtonPrimary>
    </div>
  );
};

export default Pagination;
