import { GiveawayFixture } from "@/data/giveaway_types";

export const fetchGiveaways = async (): Promise<GiveawayFixture[]> => {
  return fetch("https://www.just-free-games.com/public/giveaways.json", {
    next: { revalidate: 300 },
  })
    .then((res) => {
      if (!res.ok) {
        console.error(`HTTP error! Status: ${res.status}`);
        return [];
      }
      return res.json();
    })
    .then((data) => data)
    .catch((error) => {
      console.error("Unable to fetch data:", error);
      return [];
    });
};
