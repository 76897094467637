import { platformsAggregated, types } from "@/data/giveaway_filters";
import { GiveawayFixture } from "@/data/giveaway_types";
import { useState } from "react";
import { createGlobalState } from "react-hooks-global-state";

export const PAGE_SIZE = 25;

export type SortOrder = "recently-listed" | "ending-soon";

const initialState: {
  platformsState: string[];
  typesState: string[];
  expiredState: boolean;
  sortOrderState: SortOrder;
  limit: number;
} = {
  platformsState: [],
  typesState: [],
  expiredState: false,
  sortOrderState: "recently-listed",
  limit: PAGE_SIZE,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useGiveawaysFilters() {
  const [platformsState, setPlatformsState] = useGlobalState("platformsState");
  const [typesState, setTypesState] = useGlobalState("typesState");
  const [expiredState, setExpiredState] = useGlobalState("expiredState");
  const [sortOrderState, setSortOrderState] = useGlobalState("sortOrderState");
  const [limit, setLimit] = useGlobalState("limit");
  const [nextPageAvailable, setNextPageAvailable] = useState<boolean>(false);

  const goToNextPage = () => {
    setLimit(limit + PAGE_SIZE);
  };

  return {
    platformsState,
    setPlatformsState,
    typesState,
    setTypesState,
    expiredState,
    setExpiredState,
    sortOrderState,
    setSortOrderState,
    limit,
    goToNextPage,
    nextPageAvailable,
    setNextPageAvailable,
  };
}

const getPlatformsValues = (values: string[]) => {
  let platformValues = values
    .map((value) => {
      const platform = platformsAggregated.find((item) => item.label === value);
      return platform?.values || [];
    })
    .flat();
  // get unique values
  platformValues = platformValues.filter(
    (value, index) => platformValues.indexOf(value) === index
  );
  return platformValues;
};

const getTypesValues = (values: string[]) => {
  let typeValues = values
    .map((value) => {
      const type = types.find((item) => item.label === value);
      return type?.values || [];
    })
    .flat();
  // get unique values
  typeValues = typeValues.filter(
    (value, index) => typeValues.indexOf(value) === index
  );
  return typeValues;
};

interface SkippableFilters {
  expiration?: boolean;
  platforms?: boolean;
  types?: boolean;
}

export const isExpired = (item: GiveawayFixture) => {
  return (
    item.fields.status === "EXPIRED" ||
    (item.fields.status === "PUBLISHED" &&
      item.fields.expiration_date !== null &&
      new Date(item.fields.expiration_date).getTime() <= new Date().getTime())
  );
};

export const useGiveawaysFilteredData = (
  data: GiveawayFixture[],
  bypassFilters: SkippableFilters = {
    expiration: false,
    platforms: false,
    types: false,
  }
) => {
  const { platformsState, typesState, sortOrderState, limit } =
    useGiveawaysFilters();
  const platformsValues = getPlatformsValues(platformsState);
  const typesValues = getTypesValues(typesState);

  const includeOtherPlatforms = platformsState.includes("Other");
  const includeOtherTypes = typesState.includes("Other");

  const filteredData = data
    .filter((item: GiveawayFixture) => {
      const expired = isExpired(item);
      const published = item.fields.status === "PUBLISHED" && !expired;

      // if not bypassFilters.expiration, filter out items that are not published
      if (!bypassFilters.expiration && !published) {
        return false;
      }

      // if bypassFilters.expiration, filter out items that are not published or expired
      if (bypassFilters.expiration && !published && !expired) {
        return false;
      }

      // filter out invalid items
      if (!item.fields.title || !item.fields.url) return false;

      // filter platforms
      if (
        !bypassFilters.platforms &&
        (platformsValues.length > 0 || includeOtherPlatforms)
      ) {
        if (includeOtherPlatforms && item.fields.platforms.length === 0) {
          return true;
        }

        if (
          !platformsValues.filter((value) =>
            item.fields.platforms.includes(value)
          ).length
        )
          return false;
      }

      // filter types
      if (
        !bypassFilters.types &&
        (typesValues.length > 0 || includeOtherTypes)
      ) {
        if (includeOtherTypes && item.fields.type === "OTHER") {
          return true;
        }

        if (!typesValues.filter((value) => item.fields.type === value).length)
          return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOrderState === "recently-listed") {
        return (
          new Date(b.fields.created_at).getTime() -
          new Date(a.fields.created_at).getTime()
        );
      }
      if (sortOrderState === "ending-soon") {
        if (a.fields.expiration_date === null) return 1;
        if (b.fields.expiration_date === null) return -1;
        return (
          new Date(a.fields.expiration_date).getTime() -
          new Date(b.fields.expiration_date).getTime()
        );
      }
      return 0;
    });
  const total = filteredData.length;

  return { filteredData: filteredData.slice(0, limit), total };
};
