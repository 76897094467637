"use client";

import React, { FC } from "react";
import NcImage from "@/shared/NcImage/NcImage";
import ItemTypePlatformsIcon from "./ItemTypePlatformsIcon";
import Prices from "./Prices";
import { ClockIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { GiveawayFixture } from "@/data/giveaway_types";
import { StaticImageData } from "next/image";
import { UrlObject } from "url";
import notFoundImage from "@/images/404.png";
import { platforms } from "@/data/giveaway_filters";
import { isExpired } from "@/hooks/useGiveawaysFilters";

export interface CardProps {
  item: GiveawayFixture;
  id?: string;
  className?: string;
}

const imageProps = (item: GiveawayFixture): StaticImageData => {
  const src =
    item.fields.post_image ||
    item.fields.steam_grid_db_image ||
    notFoundImage.src;
  return {
    src: src,
    height: 215,
    width: 460,
    blurDataURL: src,
    blurWidth: 8,
    blurHeight: 8,
  };
};

const formatTimeLeft = (date: string): string => {
  const date1 = new Date();
  const date2 = new Date(date);
  if (date2.getTime() < date1.getTime()) {
    return "";
  }

  const diff = new Date(date2.getTime() - date1.getTime());
  const years = diff.getUTCFullYear() - 1970;
  const months = diff.getUTCMonth();
  const days = diff.getUTCDate() - 1;
  const hours = diff.getHours();

  let timeLeftFormatted = "";
  if (years > 0) {
    timeLeftFormatted += `${years} y `;
  }
  if (months > 0) {
    timeLeftFormatted += `${months} m `;
  }
  if (days > 0) {
    timeLeftFormatted += `${days} d `;
  }
  if (hours > 0) {
    timeLeftFormatted += `${hours} h `;
  }
  return timeLeftFormatted ? `${timeLeftFormatted} left` : "";
};

const sourceName = (item: GiveawayFixture): string => {
  switch (item.fields.supplier) {
    case "reddit":
      return "Reddit";
    case "is_there_any_deal":
      return "IsThereAnyDeal";
    default:
      return "";
  }
};

const platformsString = (item: GiveawayFixture): string => {
  const platformsString = item.fields.platforms
    .map((value) => {
      const platform = platforms.find((item) => item.values.includes(value));
      return platform?.label || "";
    })
    .filter((value) => value !== "");
  return platformsString.join(" / ");
};

const Card: FC<CardProps> = ({ item, id = "", className = "" }) => {
  const itemUrl: UrlObject = new URL(item.fields.url);
  const showMsrp = item.fields.msrp !== null && item.fields.msrp > 0;
  const expired = isExpired(item);
  const showExpirationDate = !expired && item.fields.expiration_date !== null;

  return (
    <div
      id={id}
      className={`nc-Card relative flex flex-col group ${className}`}
    >
      {/* image */}
      <div className="relative flex-shrink-0">
        <div className="flex rounded-3xl overflow-hidden z-0">
          <Link href={itemUrl} target="_blank">
            <NcImage
              src={imageProps(item)}
              alt={item.fields.title}
              containerClassName="min-w-[296px] min-h-[138px]"
              className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform rounded-3xl"
              loadingSkeleton
              unoptimized
            />
            {/* expired */}
            {expired && (
              <>
                <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-3xl"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-red-600 text-3xl font-bold uppercase">
                    Expired
                  </div>
                </div>
              </>
            )}
          </Link>
        </div>
        <div className="absolute top-3 left-3 flex">
          <ItemTypePlatformsIcon platforms={item.fields.platforms} />
        </div>
        <div className="absolute top-3 inset-x-3 flex"></div>
      </div>

      <div className="px-2 py-5 space-y-3">
        {/* type and platforms */}
        <div className="flex justify-between">
          <span className="text-neutral-700 dark:text-neutral-400 text-xs mr-4">
            {item.fields.type}
          </span>
          <span
            className="text-neutral-700 dark:text-neutral-400 text-xs truncate"
            title={platformsString(item)}
          >
            {platformsString(item)}
          </span>
        </div>

        {/* title */}
        <h2 className={`text-lg font-medium`}>
          <Link
            className="block text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
            href={itemUrl}
            target="_blank"
          >
            {item.fields.title}
          </Link>
        </h2>

        {/* description */}
        {item.fields.description && (
          <div className="flex">
            <span className="text-neutral-700 dark:text-neutral-400 text-xs">
              {item.fields.description}
            </span>
          </div>
        )}

        {/* source */}
        {item.fields.show_source === true &&
          sourceName(item) !== "" &&
          item.fields.post_url !== "" && (
            <div className="flex">
              <span className="text-neutral-700 dark:text-neutral-400 text-xs">
                <Link
                  className="hover:text-black dark:hover:text-white"
                  href={new URL(item.fields.post_url)}
                  target="_blank"
                >
                  Source: {sourceName(item)}
                </Link>
              </span>
            </div>
          )}

        {/* horizontal line */}
        {(showMsrp || showExpirationDate) && (
          <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700"></div>
        )}

        {/* price and time left */}
        <div className="flex justify-between items-center">
          <div>
            {showMsrp && (
              <Prices
                className="mt-0.5"
                price={`${item.fields.msrp?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} $`}
              />
            )}
          </div>
          {showExpirationDate && (
            <div className="flex items-center justify-end text-sm text-neutral-500 dark:text-neutral-400">
              <ClockIcon className="w-4 h-4" />
              <span className="ml-1 mt-0.5">
                {item.fields.expiration_date !== null &&
                  `${formatTimeLeft(item.fields.expiration_date)}`}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
