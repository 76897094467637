"use client";

import React, { Fragment, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonThird from "@/shared/Button/ButtonThird";
import ButtonClose from "@/shared/ButtonClose/ButtonClose";
import Checkbox from "@/shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  ArchiveBoxXMarkIcon,
  ComputerDesktopIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/solid";
import {
  giveawayPlatformsAggregatedFilter,
  giveawayTypesFilter,
} from "@/data/giveaway_filters";
import useGiveawaysFilters from "@/hooks/useGiveawaysFilters";
import ArchiveFilterListBox from "./ArchiveFilterListBox";
import { SelectProperty } from "@/data/giveaway_types";

const TabFilters = () => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const {
    platformsState,
    setPlatformsState,
    typesState,
    setTypesState,
    expiredState,
    setExpiredState,
  } = useGiveawaysFilters();

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const handleChangePlatforms = (checked: boolean, name: string) => {
    checked
      ? setPlatformsState([...platformsState, name])
      : setPlatformsState(platformsState.filter((i) => i !== name));
  };

  const handleChangeTypes = (checked: boolean, name: string) => {
    checked
      ? setTypesState([...typesState, name])
      : setTypesState(typesState.filter((i) => i !== name));
  };

  const renderXClear = (marginClassNames: string = "ml-3") => {
    const defaultClassNames = `flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center cursor-pointer`;
    return (
      <span className={`${defaultClassNames} ${marginClassNames}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsPlatforms = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 sm:py-2.5 text-sm rounded-full border focus:outline-none 
              ${open ? "!border-primary-500 " : ""}
                ${
                  !!platformsState.length
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <ComputerDesktopIcon className="w-4 h-4" />
              <span className="ml-2">Platforms</span>
              {!platformsState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setPlatformsState([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {giveawayPlatformsAggregatedFilter.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={platformsState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangePlatforms(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setPlatformsState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypes = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 sm:py-2.5 text-sm rounded-full border focus:outline-none
               ${
                 open
                   ? "!border-primary-500 "
                   : "border-neutral-300 dark:border-neutral-700"
               }
                ${
                  !!typesState.length
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <PuzzlePieceIcon className="w-4 h-4" />
              <span className="ml-2">Types</span>
              {!typesState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setTypesState([])}>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {giveawayTypesFilter.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={typesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeTypes(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setTypesState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderMoreFilterItem = (
    data: SelectProperty[],
    filterType: "type" | "platform"
  ) => {
    // split options into 2 columns
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    const defaultCheckedByType =
      filterType === "type" ? typesState : platformsState;
    const handleChangeByType =
      filterType === "type" ? handleChangeTypes : handleChangePlatforms;
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={defaultCheckedByType.includes(item.name)}
              onChange={(checked) => handleChangeByType(checked, item.name)}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={defaultCheckedByType.includes(item.name)}
              onChange={(checked) => handleChangeByType(checked, item.name)}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderTabIncludeExpired = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer  ${
          expiredState
            ? "border-primary-500 bg-primary-50 text-primary-900"
            : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
        }`}
        onClick={() => setExpiredState(!expiredState)}
      >
        <ArchiveBoxXMarkIcon className="w-4 h-4" />
        <span className="line-clamp-1 ml-2">Include Expired</span>
        {expiredState && renderXClear()}
      </div>
    );
  };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    const filtersSelected = platformsState.length + typesState.length;
    let classNames = `flex flex-shrink-0 items-center justify-center text-sm rounded-full border border-primary-500`;
    classNames += filtersSelected
      ? ` bg-primary-50 text-primary-900 focus:outline-none cursor-pointer`
      : ` border-neutral-300 dark:border-neutral-700 dark:hover:border-neutral-500 dark:text-neutral-300 hover:border-neutral-400 text-neutral-700 focus:outline-none cursor-pointer`;
    return (
      <div className="flex-shrink-0">
        <div className={classNames}>
          <span className="px-4 py-2" onClick={openModalMoreFilter}>
            Filters &nbsp;
            {filtersSelected > 0 && <span>({filtersSelected})</span>}
          </span>
          {filtersSelected > 0 && (
            <span onClick={() => setPlatformsState([])}>
              {renderXClear("ml-0 mr-3")}
            </span>
          )}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Platforms</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(
                            giveawayPlatformsAggregatedFilter,
                            "platform"
                          )}
                        </div>
                      </div>

                      <div className="py-7">
                        <h3 className="text-xl font-medium">Types</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(giveawayTypesFilter, "type")}
                        </div>
                      </div>

                      <div className="py-7">
                        <div className="relative ">
                          {renderTabIncludeExpired()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setTypesState([]);
                        setPlatformsState([]);
                        closeModalMoreFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4 flex-col lg:flex-row justify-between">
      {/* FOR DESKTOP */}
      <div className="hidden lg:flex space-x-4">
        {renderTabsPlatforms()}
        {renderTabsTypes()}
        {renderTabIncludeExpired()}
        <ArchiveFilterListBox />
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className="flex lg:hidden space-x-4 justify-between">
        {renderTabMobileFilter()}
        <ArchiveFilterListBox />
      </div>
    </div>
  );
};

export default TabFilters;
