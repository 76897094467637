"use client";

import React, { FC, useEffect, useState } from "react";
import useGiveawaysFilters, {
  useGiveawaysFilteredData,
} from "@/hooks/useGiveawaysFilters";
import Card from "@/components/Card";
import { GiveawayFixture } from "@/data/giveaway_types";
import { fetchGiveaways } from "@/utils/fetchGiveaways";

const Giveaways: FC = () => {
  const [data, setData] = useState<GiveawayFixture[]>([]);
  const { expiredState } = useGiveawaysFilters();
  const { filteredData } = useGiveawaysFilteredData(data, {
    expiration: expiredState,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchGiveaways();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
      {filteredData.map((item) => (
        <Card item={item} key={item.pk} id={`${item.pk}`} />
      ))}
    </div>
  );
};

export default Giveaways;
